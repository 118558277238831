import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as indexJFmZsJLqDNMeta } from "/vercel/path0/pages/biz/training/course/first-prompt-engineering/index.vue?macro=true";
import { default as indexM8DIAntHPLMeta } from "/vercel/path0/pages/biz/training/dx/index.vue?macro=true";
import { default as indexVf2tJc0DDdMeta } from "/vercel/path0/pages/biz/training/index.vue?macro=true";
import { default as completedK2AH0qYdt3Meta } from "/vercel/path0/pages/biz/training/it/completed.vue?macro=true";
import { default as completedWARbiWBNwrMeta } from "/vercel/path0/pages/biz/training/it/contact/completed.vue?macro=true";
import { default as indexFcCQAzUsY1Meta } from "/vercel/path0/pages/biz/training/it/contact/index.vue?macro=true";
import { default as indexTmXCHXCeMeMeta } from "/vercel/path0/pages/biz/training/it/index.vue?macro=true";
import { default as index9FE4cnbC5WMeta } from "/vercel/path0/pages/biz/training/lp/team/[team]/index.vue?macro=true";
import { default as indexSliKKjDjKJMeta } from "/vercel/path0/pages/biz/training/special/generative-ai-series/index.vue?macro=true";
import { default as htmlcss_45trial2MRIXkk7BlMeta } from "/vercel/path0/pages/biz/training/trial/htmlcss-trial.vue?macro=true";
import { default as worksjm4aiFWIsOMeta } from "/vercel/path0/pages/biz/works.vue?macro=true";
import { default as aiFT7foetBqbMeta } from "/vercel/path0/pages/briefing/ai.vue?macro=true";
import { default as allNbqKyRmQnUMeta } from "/vercel/path0/pages/briefing/all.vue?macro=true";
import { default as androidoqYHj5oSgPMeta } from "/vercel/path0/pages/briefing/android.vue?macro=true";
import { default as datascienceD8sSIKy6CsMeta } from "/vercel/path0/pages/briefing/datascience.vue?macro=true";
import { default as first_45programmingFkwnG19YIBMeta } from "/vercel/path0/pages/briefing/first-programming.vue?macro=true";
import { default as first_45prompt_45engineering774RbDQ1NvMeta } from "/vercel/path0/pages/briefing/first-prompt-engineering.vue?macro=true";
import { default as first_45sidejobGLcWrLlh5eMeta } from "/vercel/path0/pages/briefing/first-sidejob.vue?macro=true";
import { default as frontendAoJ0WCA9F6Meta } from "/vercel/path0/pages/briefing/frontend.vue?macro=true";
import { default as indexWLeBWnlbCDMeta } from "/vercel/path0/pages/briefing/index.vue?macro=true";
import { default as iphonetqF7T6DK3GMeta } from "/vercel/path0/pages/briefing/iphone.vue?macro=true";
import { default as javasft0TaKE1BMeta } from "/vercel/path0/pages/briefing/java.vue?macro=true";
import { default as aiBBkgQd2VWHMeta } from "/vercel/path0/pages/briefing/lp-s/ai.vue?macro=true";
import { default as allQuqEBm8FzbMeta } from "/vercel/path0/pages/briefing/lp-s/all.vue?macro=true";
import { default as androidob7Te7hi1JMeta } from "/vercel/path0/pages/briefing/lp-s/android.vue?macro=true";
import { default as datascienceagGSrRlbjxMeta } from "/vercel/path0/pages/briefing/lp-s/datascience.vue?macro=true";
import { default as first_45programming6uQ4bdETLNMeta } from "/vercel/path0/pages/briefing/lp-s/first-programming.vue?macro=true";
import { default as first_45prompt_45engineeringsVzLk5jdfQMeta } from "/vercel/path0/pages/briefing/lp-s/first-prompt-engineering.vue?macro=true";
import { default as first_45sidejobKDX5KmD914Meta } from "/vercel/path0/pages/briefing/lp-s/first-sidejob.vue?macro=true";
import { default as frontendrbZ9HTLpROMeta } from "/vercel/path0/pages/briefing/lp-s/frontend.vue?macro=true";
import { default as iphoneTZFaLXX3xUMeta } from "/vercel/path0/pages/briefing/lp-s/iphone.vue?macro=true";
import { default as javaEX9K1nzQ0RMeta } from "/vercel/path0/pages/briefing/lp-s/java.vue?macro=true";
import { default as programmingGdnt3ySuADMeta } from "/vercel/path0/pages/briefing/lp-s/programming.vue?macro=true";
import { default as pythonxKD3P7wSr6Meta } from "/vercel/path0/pages/briefing/lp-s/python.vue?macro=true";
import { default as unity4gLLRvL4aHMeta } from "/vercel/path0/pages/briefing/lp-s/unity.vue?macro=true";
import { default as webdesignAzaiAIb9MCMeta } from "/vercel/path0/pages/briefing/lp-s/webdesign.vue?macro=true";
import { default as webmarketingyQ7TBLoQZ1Meta } from "/vercel/path0/pages/briefing/lp-s/webmarketing.vue?macro=true";
import { default as moocres3OwH9u7qX3Meta } from "/vercel/path0/pages/briefing/moocres.vue?macro=true";
import { default as pythontpvJAy4SEiMeta } from "/vercel/path0/pages/briefing/python.vue?macro=true";
import { default as unityLYTPgPUvE7Meta } from "/vercel/path0/pages/briefing/unity.vue?macro=true";
import { default as webdesigncen2ZWKUwaMeta } from "/vercel/path0/pages/briefing/webdesign.vue?macro=true";
import { default as webmarketingL9WBHzJ0K4Meta } from "/vercel/path0/pages/briefing/webmarketing.vue?macro=true";
import { default as index79ETaXHGYLMeta } from "/vercel/path0/pages/career-about/index.vue?macro=true";
import { default as indexTHqTRxlhV2Meta } from "/vercel/path0/pages/company/index.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as indexuIEwXf9C56Meta } from "/vercel/path0/pages/cookiepolicy/index.vue?macro=true";
import { default as htmlcss_45schedule_45trial1pjQItb8A88Meta } from "/vercel/path0/pages/counseling/htmlcss-schedule-trial1.vue?macro=true";
import { default as htmlcss_45schedule_45trial2BfGndNgumdMeta } from "/vercel/path0/pages/counseling/htmlcss-schedule-trial2.vue?macro=true";
import { default as htmlcss_45scheduleHftv4G6zwkMeta } from "/vercel/path0/pages/counseling/htmlcss-schedule.vue?macro=true";
import { default as htmlcss_45schedule_45formafT3JCCY7NMeta } from "/vercel/path0/pages/counseling/lp/htmlcss-schedule-form.vue?macro=true";
import { default as htmlcss_45schedule491xgB0GzFMeta } from "/vercel/path0/pages/counseling/lp/htmlcss-schedule.vue?macro=true";
import { default as aiPZjNXcOHtRMeta } from "/vercel/path0/pages/course/ai.vue?macro=true";
import { default as androidEvBkWCl8EBMeta } from "/vercel/path0/pages/course/android.vue?macro=true";
import { default as datascienceb4Dg3ZbqgIMeta } from "/vercel/path0/pages/course/datascience.vue?macro=true";
import { default as design_45portfoliodT6w9ZomacMeta } from "/vercel/path0/pages/course/design-portfolio.vue?macro=true";
import { default as first_45programmingmkQu7bXJqkMeta } from "/vercel/path0/pages/course/first-programming.vue?macro=true";
import { default as first_45prompt_45engineeringN9FSZunrsVMeta } from "/vercel/path0/pages/course/first-prompt-engineering.vue?macro=true";
import { default as first_45sidejobK4KjAQBCNtMeta } from "/vercel/path0/pages/course/first-sidejob.vue?macro=true";
import { default as frontendYVaVm5Fd8FMeta } from "/vercel/path0/pages/course/frontend.vue?macro=true";
import { default as indexfkRBc6uRMrMeta } from "/vercel/path0/pages/course/index.vue?macro=true";
import { default as iphoneKvxyFmnDaXMeta } from "/vercel/path0/pages/course/iphone.vue?macro=true";
import { default as javaCpa4UKKS87Meta } from "/vercel/path0/pages/course/java.vue?macro=true";
import { default as llm_45developmentYEUJfSaxx8Meta } from "/vercel/path0/pages/course/llm-development.vue?macro=true";
import { default as occupationffrGnJ1K1rMeta } from "/vercel/path0/pages/course/occupation.vue?macro=true";
import { default as pythonLSBQCWGOgdMeta } from "/vercel/path0/pages/course/python.vue?macro=true";
import { default as unityPfzMLzyQZRMeta } from "/vercel/path0/pages/course/unity.vue?macro=true";
import { default as webdesignzxtamkRGceMeta } from "/vercel/path0/pages/course/webdesign.vue?macro=true";
import { default as webdevelopment_45advancedeWh7EFUNM1Meta } from "/vercel/path0/pages/course/webdevelopment-advanced.vue?macro=true";
import { default as webmarketingEJdvbmXjHHMeta } from "/vercel/path0/pages/course/webmarketing.vue?macro=true";
import { default as completed_a7gPeWqF67jMeta } from "/vercel/path0/pages/diagnoses/completed_a.vue?macro=true";
import { default as completed_bbnbeRPPWHpMeta } from "/vercel/path0/pages/diagnoses/completed_b.vue?macro=true";
import { default as completed_cgh3OO7r4WVMeta } from "/vercel/path0/pages/diagnoses/completed_c.vue?macro=true";
import { default as completed_dX1e4RplamUMeta } from "/vercel/path0/pages/diagnoses/completed_d.vue?macro=true";
import { default as completed_eD8zMBDBK96Meta } from "/vercel/path0/pages/diagnoses/completed_e.vue?macro=true";
import { default as completed_fA8bKPbdwb6Meta } from "/vercel/path0/pages/diagnoses/completed_f.vue?macro=true";
import { default as completed_gssJYDJsQr0Meta } from "/vercel/path0/pages/diagnoses/completed_g.vue?macro=true";
import { default as completed_isPVNPuVTtyMeta } from "/vercel/path0/pages/diagnoses/completed_i.vue?macro=true";
import { default as completed_jpFNTP6gKYzMeta } from "/vercel/path0/pages/diagnoses/completed_j.vue?macro=true";
import { default as completed_kfqdu3TYFgKMeta } from "/vercel/path0/pages/diagnoses/completed_k.vue?macro=true";
import { default as completed_lgNhJvf2A3WMeta } from "/vercel/path0/pages/diagnoses/completed_l.vue?macro=true";
import { default as completed_nXAc9TiA0CKMeta } from "/vercel/path0/pages/diagnoses/completed_n.vue?macro=true";
import { default as completed_odUe2A7cV5UMeta } from "/vercel/path0/pages/diagnoses/completed_o.vue?macro=true";
import { default as completed_pYbkne6HDV3Meta } from "/vercel/path0/pages/diagnoses/completed_p.vue?macro=true";
import { default as completed_qYzsGpHsPAQMeta } from "/vercel/path0/pages/diagnoses/completed_q.vue?macro=true";
import { default as completed_ruLO7xwUZW1Meta } from "/vercel/path0/pages/diagnoses/completed_r.vue?macro=true";
import { default as completed_scHlDGnsgDxMeta } from "/vercel/path0/pages/diagnoses/completed_s.vue?macro=true";
import { default as completed_t7rwAxEdQVxMeta } from "/vercel/path0/pages/diagnoses/completed_t.vue?macro=true";
import { default as q1bfdinuVYi9Meta } from "/vercel/path0/pages/diagnoses/q1.vue?macro=true";
import { default as q2_aBpKZ8qldvFMeta } from "/vercel/path0/pages/diagnoses/q2_a.vue?macro=true";
import { default as q2_bBg0jHoC3kjMeta } from "/vercel/path0/pages/diagnoses/q2_b.vue?macro=true";
import { default as q2_cp8O6wPMhowMeta } from "/vercel/path0/pages/diagnoses/q2_c.vue?macro=true";
import { default as q2_dmpziMC4GvjMeta } from "/vercel/path0/pages/diagnoses/q2_d.vue?macro=true";
import { default as q2_eoQ2AginqY1Meta } from "/vercel/path0/pages/diagnoses/q2_e.vue?macro=true";
import { default as q3_ala8GrIjZM4Meta } from "/vercel/path0/pages/diagnoses/q3_a.vue?macro=true";
import { default as q3_bG4GSsVa2JKMeta } from "/vercel/path0/pages/diagnoses/q3_b.vue?macro=true";
import { default as q3_cMDMHLIXFezMeta } from "/vercel/path0/pages/diagnoses/q3_c.vue?macro=true";
import { default as q3_fqoV6IEEcW1Meta } from "/vercel/path0/pages/diagnoses/q3_f.vue?macro=true";
import { default as q4_a3AQQwgBd5FMeta } from "/vercel/path0/pages/diagnoses/q4_a.vue?macro=true";
import { default as topyz3qXdT99tMeta } from "/vercel/path0/pages/diagnoses/top.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as aiqLe1eptdUDMeta } from "/vercel/path0/pages/job/ai.vue?macro=true";
import { default as android5oSmniTYKmMeta } from "/vercel/path0/pages/job/android.vue?macro=true";
import { default as profile0o0bBX4Lk3Meta } from "/vercel/path0/pages/job/candidate/[token]/profile.vue?macro=true";
import { default as datasciencewu4JKMzTcAMeta } from "/vercel/path0/pages/job/datascience.vue?macro=true";
import { default as frontendtt9BOwnvSqMeta } from "/vercel/path0/pages/job/frontend.vue?macro=true";
import { default as index358wnQ2UHrMeta } from "/vercel/path0/pages/job/index.vue?macro=true";
import { default as iphone4bjWyWbREZMeta } from "/vercel/path0/pages/job/iphone.vue?macro=true";
import { default as javaq81kz6V24NMeta } from "/vercel/path0/pages/job/java.vue?macro=true";
import { default as laravelBaxPKv7vtqMeta } from "/vercel/path0/pages/job/laravel.vue?macro=true";
import { default as phpgW1dduXftSMeta } from "/vercel/path0/pages/job/php.vue?macro=true";
import { default as unityzo8H9gWYgiMeta } from "/vercel/path0/pages/job/unity.vue?macro=true";
import { default as webdesigna0O72CvT71Meta } from "/vercel/path0/pages/job/webdesign.vue?macro=true";
import { default as webmarketingbE6PZeucjeMeta } from "/vercel/path0/pages/job/webmarketing.vue?macro=true";
import { default as indexJPPXbETK6DMeta } from "/vercel/path0/pages/jobchange/index.vue?macro=true";
import { default as indexwBWcviDWKwMeta } from "/vercel/path0/pages/law/index.vue?macro=true";
import { default as first_45sidejob_45immersiveN3IFogN2lEMeta } from "/vercel/path0/pages/lp/course/first-sidejob-immersive.vue?macro=true";
import { default as first_45sidejobm7hQdb82zdMeta } from "/vercel/path0/pages/lp/course/first-sidejob.vue?macro=true";
import { default as schedule_45formUCvohPgBYMMeta } from "/vercel/path0/pages/lp/moocres/schedule-form.vue?macro=true";
import { default as contact_45bizctG1WHsDkWMeta } from "/vercel/path0/pages/ly/contact-biz.vue?macro=true";
import { default as contactcqWo7WTDl2Meta } from "/vercel/path0/pages/ly/contact.vue?macro=true";
import { default as counseling_4522pY7dShtTbMeta } from "/vercel/path0/pages/ly/counseling-2.vue?macro=true";
import { default as counseling_45lineVvhKsEKDN5Meta } from "/vercel/path0/pages/ly/counseling-line.vue?macro=true";
import { default as counseling84rvMWUo9EMeta } from "/vercel/path0/pages/ly/counseling.vue?macro=true";
import { default as data_45basicp9s8hDhWNNMeta } from "/vercel/path0/pages/ly/course/data-basic.vue?macro=true";
import { default as jobchangeHICJGBzcIPMeta } from "/vercel/path0/pages/ly/course/jobchange.vue?macro=true";
import { default as webapp_45basicQ1WBVL3C20Meta } from "/vercel/path0/pages/ly/course/webapp-basic.vue?macro=true";
import { default as webappPuPOsENa37Meta } from "/vercel/path0/pages/ly/course/webapp.vue?macro=true";
import { default as lawpR8FVHyqAKMeta } from "/vercel/path0/pages/ly/law.vue?macro=true";
import { default as loginXmmZrncU0JMeta } from "/vercel/path0/pages/ly/login.vue?macro=true";
import { default as counseling_452Cvs4AggP2rMeta } from "/vercel/path0/pages/ly/lp/counseling-2.vue?macro=true";
import { default as counseling_453d8s3lSBMHDMeta } from "/vercel/path0/pages/ly/lp/counseling-3.vue?macro=true";
import { default as counseling_454K6JWTQzgHxMeta } from "/vercel/path0/pages/ly/lp/counseling-4.vue?macro=true";
import { default as counseling_45kakakufyAurXKAnqMeta } from "/vercel/path0/pages/ly/lp/counseling-kakaku.vue?macro=true";
import { default as counselingyQmIPvUburMeta } from "/vercel/path0/pages/ly/lp/counseling.vue?macro=true";
import { default as privacypolicy6EhgJwAWQWMeta } from "/vercel/path0/pages/ly/privacypolicy.vue?macro=true";
import { default as termsUoYkUh4pqCMeta } from "/vercel/path0/pages/ly/terms.vue?macro=true";
import { default as trial_45mentoringoHiCcFNejhMeta } from "/vercel/path0/pages/ly/tutorial/trial-mentoring.vue?macro=true";
import { default as passwords1mFR4I4G80Meta } from "/vercel/path0/pages/ly/users/passwords.vue?macro=true";
import { default as indexXZMS3IsWcBMeta } from "/vercel/path0/pages/price/index.vue?macro=true";
import { default as indexbg3ekTJ1BXMeta } from "/vercel/path0/pages/privacypolicy/index.vue?macro=true";
import { default as first_45sidejob_45webdesignxybyjMOpW2Meta } from "/vercel/path0/pages/set/first-sidejob-webdesign.vue?macro=true";
import { default as iphone_45androidpqBtVspDaEMeta } from "/vercel/path0/pages/set/iphone-android.vue?macro=true";
import { default as python_45ai_45datascienceqZYsn8wc7LMeta } from "/vercel/path0/pages/set/python-ai-datascience.vue?macro=true";
import { default as python_45aiQjG8MQ18DhMeta } from "/vercel/path0/pages/set/python-ai.vue?macro=true";
import { default as python_45datascience9wyAcvMpLQMeta } from "/vercel/path0/pages/set/python-datascience.vue?macro=true";
import { default as python_45first_45prompt_45engineeringep5PJlJXswMeta } from "/vercel/path0/pages/set/python-first-prompt-engineering.vue?macro=true";
import { default as unity_45androidUvtN2FpeVXMeta } from "/vercel/path0/pages/set/unity-android.vue?macro=true";
import { default as unity_45iphoneuyNSbsSl3nMeta } from "/vercel/path0/pages/set/unity-iphone.vue?macro=true";
import { default as webdesign_45webmarketing0wr2d0QXgZMeta } from "/vercel/path0/pages/set/webdesign-webmarketing.vue?macro=true";
import { default as webdevelopment_45advancedWOU6ZQPOBAMeta } from "/vercel/path0/pages/set/webdevelopment-advanced.vue?macro=true";
import { default as indexXpBMzYjdBGMeta } from "/vercel/path0/pages/sidejob/index.vue?macro=true";
import { default as index9OiDgfx7OcMeta } from "/vercel/path0/pages/terms/index.vue?macro=true";
import { default as photoshopQ4nCfYvXyRMeta } from "/vercel/path0/pages/training/photoshop.vue?macro=true";
import { default as htmlcss_45trialddW2Q8b4jpMeta } from "/vercel/path0/pages/trial/htmlcss-trial.vue?macro=true";
import { default as htmlcss_45trial_45scheduleqKumSTQjFjMeta } from "/vercel/path0/pages/trial/lp/htmlcss-trial-schedule.vue?macro=true";
import { default as htmlcss_45trial_45sidejobab3U7GQSE0Meta } from "/vercel/path0/pages/trial/lp/htmlcss-trial-sidejob.vue?macro=true";
import { default as htmlcss_45trialPfrdFUilwJMeta } from "/vercel/path0/pages/trial/lp/htmlcss-trial.vue?macro=true";
import { default as trial_45mentoring2AxcvMMZMXMeta } from "/vercel/path0/pages/tutorial/trial-mentoring.vue?macro=true";
import { default as axiosubyGCjAC35Meta } from "/vercel/path0/pages/upgrade/axios.vue?macro=true";
import { default as componentsSnzgPEvYO3Meta } from "/vercel/path0/pages/upgrade/components.vue?macro=true";
import { default as headingmVL8PTQRiiMeta } from "/vercel/path0/pages/upgrade/css/heading.vue?macro=true";
import { default as accordionmgk0v1Byg8Meta } from "/vercel/path0/pages/upgrade/daisyui/accordion.vue?macro=true";
import { default as collapsemdHVLdJdHDMeta } from "/vercel/path0/pages/upgrade/daisyui/collapse.vue?macro=true";
import { default as dropdown9qrN6RGPbfMeta } from "/vercel/path0/pages/upgrade/daisyui/dropdown.vue?macro=true";
import { default as form_45designUuRhUeXl5eMeta } from "/vercel/path0/pages/upgrade/daisyui/form-design.vue?macro=true";
import { default as formpDujayqBHiMeta } from "/vercel/path0/pages/upgrade/daisyui/form.vue?macro=true";
import { default as modal_45appyformvnqMnCHJH5Meta } from "/vercel/path0/pages/upgrade/daisyui/modal-appyform.vue?macro=true";
import { default as modal_45footer_45buttonVyQWmYquVbMeta } from "/vercel/path0/pages/upgrade/daisyui/modal-footer-button.vue?macro=true";
import { default as modalpnbYtYl3r0Meta } from "/vercel/path0/pages/upgrade/daisyui/modal.vue?macro=true";
import { default as tooltipCBx1U8OH86Meta } from "/vercel/path0/pages/upgrade/daisyui/tooltip.vue?macro=true";
import { default as _02_tailwindNBIfcYq8u7Meta } from "/vercel/path0/pages/upgrade/docs/how_to/02_tailwind.vue?macro=true";
import { default as course_45iconUW8aWxrCUKMeta } from "/vercel/path0/pages/upgrade/fontawesome/course-icon.vue?macro=true";
import { default as indexRZKSjrO6AnMeta } from "/vercel/path0/pages/upgrade/fontawesome/index.vue?macro=true";
import { default as headGzyRj811AEMeta } from "/vercel/path0/pages/upgrade/head.vue?macro=true";
import { default as intersection_45observerCVHPuUU2YRMeta } from "/vercel/path0/pages/upgrade/inview/intersection-observer.vue?macro=true";
import { default as vueuse_45element_45visibilityjheHPoZrgRMeta } from "/vercel/path0/pages/upgrade/inview/vueuse-element-visibility.vue?macro=true";
import { default as vueuse_45intersection_45observerZFgZJKk8nyMeta } from "/vercel/path0/pages/upgrade/inview/vueuse-intersection-observer.vue?macro=true";
import { default as collapse_45panelE2FwQp4X1FMeta } from "/vercel/path0/pages/upgrade/jquery/collapse-panel.vue?macro=true";
import { default as iconJBpvaIaY3pMeta } from "/vercel/path0/pages/upgrade/jquery/icon.vue?macro=true";
import { default as layout_45briefingLJkaj364qmMeta } from "/vercel/path0/pages/upgrade/layout/layout-briefing.vue?macro=true";
import { default as layout_45default0Kr9I4Qd8fMeta } from "/vercel/path0/pages/upgrade/layout/layout-default.vue?macro=true";
import { default as layout_45simple_45catchcopylp82sfhp6iMeta } from "/vercel/path0/pages/upgrade/layout/layout-simple-catchcopy.vue?macro=true";
import { default as layout_45simpleywx3vsCuo2Meta } from "/vercel/path0/pages/upgrade/layout/layout-simple.vue?macro=true";
import { default as responsive_45reviewsQ3bt4SADciMeta } from "/vercel/path0/pages/upgrade/nuxt-swiper/responsive-reviews.vue?macro=true";
import { default as responsive_45slide_45three8Jpn0biHDbMeta } from "/vercel/path0/pages/upgrade/nuxt-swiper/responsive-slide-three.vue?macro=true";
import { default as responsive_45slide_45twoZcZ71LSVGtMeta } from "/vercel/path0/pages/upgrade/nuxt-swiper/responsive-slide-two.vue?macro=true";
import { default as index7EA7jxaZRQMeta } from "/vercel/path0/pages/upgrade/scroll-spy/index.vue?macro=true";
import { default as indexaZr2Bf6kIxMeta } from "/vercel/path0/pages/upgrade/scroll-trigger/index.vue?macro=true";
import { default as indexWjR5SfqetHMeta } from "/vercel/path0/pages/upgrade/smooth-scroll/index.vue?macro=true";
import { default as indexdiFdJEMhHTMeta } from "/vercel/path0/pages/upgrade/vue3-lottie/index.vue?macro=true";
import { default as indexVhB5W0MzMMMeta } from "/vercel/path0/pages/upgrade/youtube/index.vue?macro=true";
import { default as indexcHM0maJ3uRMeta } from "/vercel/path0/pages/works/index.vue?macro=true";
export default [
  {
    path: "/course/shopify",
    redirect: "/course"
  },
  {
    path: "/course/wix",
    redirect: "/course"
  },
  {
    path: "/course/appdesign",
    redirect: "/course"
  },
  {
    path: "/course/frontend-sidejob",
    redirect: "/course/webdevelopment-advanced"
  },
  {
    path: "/course/webdesign-freelance",
    redirect: "/course"
  },
  {
    path: "/course/rails",
    redirect: "/course"
  },
  {
    path: "/course/php",
    redirect: "/course"
  },
  {
    path: "/course/first-sidejob-mom",
    redirect: "/course"
  },
  {
    path: "/course/first-sidejob-Immersive",
    redirect: "/course"
  },
  {
    path: "/course/first-sidejob-special",
    redirect: "/course"
  },
  {
    path: "/course/webdesign-special",
    redirect: "/course"
  },
  {
    path: "/course/wordpress",
    redirect: "/course"
  },
  {
    path: "/set/webdesign-freelance-high-class-mentor",
    redirect: "/course"
  },
  {
    path: "/set/appdesign-iphone",
    redirect: "/course"
  },
  {
    path: "/set/iphone-android-appdesign",
    redirect: "/course"
  },
  {
    path: "/set/frontend-sidejob",
    redirect: "/set/webdevelopment-advanced"
  },
  {
    path: "/set/webdesign-freelance",
    redirect: "/course"
  },
  {
    path: "/set/rails-frontend",
    redirect: "/course"
  },
  {
    path: "/set/rails-frontend-webdesign",
    redirect: "/course"
  },
  {
    path: "/set/php-frontend",
    redirect: "/course"
  },
  {
    path: "/set/php-frontend-webdesign",
    redirect: "/course"
  },
  {
    path: "/set/wordpress-webmarketing",
    redirect: "/course"
  },
  {
    path: "/set/webdesign-wordpress",
    redirect: "/course"
  },
  {
    path: "/set/webdevelopment-advanced-special",
    redirect: "/set/webdevelopment-advanced"
  },
  {
    path: "/briefing/lp-s/appdesign",
    redirect: "/briefing"
  },
  {
    path: "/briefing/lp-s/shopify",
    redirect: "/briefing"
  },
  {
    path: "/briefing/office",
    redirect: "/briefing"
  },
  {
    path: "/briefing/rails",
    redirect: "/briefing"
  },
  {
    path: "/briefing/lp-s/rails",
    redirect: "/briefing"
  },
  {
    path: "/briefing/lp-s/php",
    redirect: "/briefing"
  },
  {
    path: "/briefing/php",
    redirect: "/briefing"
  },
  {
    path: "/briefing/wordpress",
    redirect: "/briefing"
  },
  {
    path: "/briefing/lp-s/wordpress",
    redirect: "/briefing"
  },
  {
    path: "/training/htmlcss",
    redirect: "/course"
  },
  {
    path: "/training/bootstrap",
    redirect: "/course"
  },
  {
    path: "/training/github",
    redirect: "/course"
  },
  {
    path: "/pro/lp-s/jobchange-trial",
    redirect: "/"
  },
  {
    path: "/pro/jobchange",
    redirect: "/"
  },
  {
    path: "/job/appdesign",
    redirect: "/job"
  },
  {
    path: "/job/blockchain",
    redirect: "/job"
  },
  {
    path: "/job/lp/appdesign",
    redirect: "/job"
  },
  {
    path: "/job/rails",
    redirect: "/job"
  },
  {
    path: "/job/wordpress",
    redirect: "/job"
  },
  {
    path: "/ly/briefing",
    redirect: "/ly/counseling"
  },
  {
    path: "/ly/notification",
    redirect: "/ly/counseling"
  },
  {
    path: "/ly/course/pre",
    redirect: "/ly/counseling"
  },
  {
    path: "/ly/course",
    redirect: "/ly/counseling"
  },
  {
    path: "/ly/lp/counseling-women",
    redirect: "/ly/lp/counseling-2"
  },
  {
    path: "/ly/course/webapp-women",
    redirect: "/ly/course/webapp"
  },
  {
    path: "/ly/counseling-2",
    redirect: "/ly/counseling"
  },
  {
    path: "/ly/lp/counseling-2",
    redirect: "/ly/lp/counseling"
  },
  {
    path: "/ly/lp/counseling-3",
    redirect: "/ly/lp/counseling"
  },
  {
    path: "/ly/lp/counseling-4",
    redirect: "/ly/lp/counseling"
  },
  {
    path: "/seminar/lp-s",
    redirect: "/"
  },
  {
    path: "/seminar/top",
    redirect: "/"
  },
  {
    path: "/seminar/freelance",
    redirect: "/"
  },
  {
    path: "/seminar/learning",
    redirect: "/"
  },
  {
    path: "/seminar/earn",
    redirect: "/"
  },
  {
    path: "/seminar/mentor-support",
    redirect: "/"
  },
  {
    path: "/seminar/works",
    redirect: "/"
  },
  {
    path: "/diagnoses/completed_m",
    redirect: "/diagnoses/top"
  },
  {
    path: "/biz/training/lp/tis",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/tis/rails",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/rails",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/ai",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/android",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/appdesign",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/automation-with-python",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/aws",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/business-development",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/csharp",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/clang",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/data-analysis",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/datascience",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/db-design",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/docker",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/dotnet",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/dx-management",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/first-ai",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/first-dx",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/first-programming",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/frontend",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/gas",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/git-seminar",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/iphone",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/it-literacy",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/it-passport",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/it-system",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/java-bronze",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/java-silver",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/java",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/linux",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/lpic-1",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/node-js",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/oop",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/php",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/python",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/rails",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/salesforce-administrator",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/security",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/shopify",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/spring-boot",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/sql",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/swtest-basic",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/unity",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/webdesign",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/webdirection",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/webmarketing",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/windows-server",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/konicaminolta/wordpress",
    redirect: "/biz/training"
  },
  {
    path: "/biz/training/lp/sevenbank/first-programming",
    redirect: "/biz/training"
  },
  {
    path: "/sidejob/lp/sidejob-detail",
    redirect: "/sidejob"
  },
  {
    path: "/works/lp/works-detail",
    redirect: "/works"
  },
  {
    path: "/works/counseling/completed",
    redirect: "/works"
  },
  {
    path: "/tutorial/counseling",
    redirect: "/"
  },
  {
    path: "/htmlcss-trial",
    redirect: "/trial/htmlcss-trial"
  },
  {
    path: "/htmlcss-trial-s2",
    redirect: "/trial/lp/htmlcss-trial"
  },
  {
    path: "/trial/lp/htmlcss-trial-2",
    redirect: "/trial/lp/htmlcss-trial"
  },
  {
    path: "/trial/lp/htmlcss-trial-line",
    redirect: "/trial/lp/htmlcss-trial"
  },
  {
    path: "/briefing/webapp",
    redirect: "/briefing/rails"
  },
  {
    path: "/biz/lp-dx",
    redirect: "/biz/training/dx"
  },
  {
    path: "/briefing-lp-webapp-s",
    redirect: "/briefing/lp-s/rails"
  },
  {
    path: "/briefing-lp-php-s",
    redirect: "/briefing/lp-s/php"
  },
  {
    path: "/briefing-lp-java-s",
    redirect: "/briefing/lp-s/java"
  },
  {
    path: "/briefing-lp-frontend-s",
    redirect: "/briefing/lp-s/frontend"
  },
  {
    path: "/briefing-lp-wordpress-s",
    redirect: "/briefing/lp-s/wordpress"
  },
  {
    path: "/briefing-lp-iphone-s",
    redirect: "/briefing/lp-s/iphone"
  },
  {
    path: "/briefing-lp-android-s",
    redirect: "/briefing/lp-s/android"
  },
  {
    path: "/briefing-lp-unity-s",
    redirect: "/briefing/lp-s/unity"
  },
  {
    path: "/briefing-lp-first-programming-s",
    redirect: "/briefing/lp-s/first-programming"
  },
  {
    path: "/briefing-lp-python-s",
    redirect: "/briefing/lp-s/python"
  },
  {
    path: "/briefing-lp-ai-s",
    redirect: "/briefing/lp-s/ai"
  },
  {
    path: "/briefing-lp-datascience-s",
    redirect: "/briefing/lp-s/datascience"
  },
  {
    path: "/briefing-lp-node-js-s",
    redirect: "/"
  },
  {
    path: "/briefing/lp-s/node-js",
    redirect: "/"
  },
  {
    path: "/briefing-lp-gas-s",
    redirect: "/briefing/lp-s/gas"
  },
  {
    path: "/briefing-lp-webdesign-s",
    redirect: "/briefing/lp-s/webdesign"
  },
  {
    path: "/briefing-lp-appdesign-s",
    redirect: "/briefing/lp-s/appdesign"
  },
  {
    path: "/briefing-lp-videoediting-s",
    redirect: "/briefing/lp-s/videoediting"
  },
  {
    path: "/briefing-lp-video-ad-s",
    redirect: "/briefing/lp-s/video-ad"
  },
  {
    path: "/briefing-lp-shopify-s",
    redirect: "/briefing/lp-s/shopify"
  },
  {
    path: "/briefing-lp-webdirection-s",
    redirect: "/briefing/lp-s/webdirection"
  },
  {
    path: "/briefing-lp-webmarketing-s",
    redirect: "/briefing/lp-s/webmarketing"
  },
  {
    path: "/briefing-lp-dx-s",
    redirect: "/briefing/lp-s/dx"
  },
  {
    path: "/briefing-lp-first-sidejob-s",
    redirect: "/briefing/lp-s/first-sidejob"
  },
  {
    path: "/briefing-lp-s",
    redirect: "/briefing/lp-s/programming"
  },
  {
    path: "/briefing-lp-all-s",
    redirect: "/briefing/lp-s/all"
  },
  {
    path: "/rails-bootcamp",
    redirect: "/course/rails"
  },
  {
    path: "/first-sidejob-bootcamp",
    redirect: "/course/first-sidejob"
  },
  {
    path: "/webdesign-bootcamp",
    redirect: "/course/webdesign"
  },
  {
    path: "/python-bootcamp",
    redirect: "/course/python"
  },
  {
    path: "/jobs",
    redirect: "/job"
  },
  {
    path: "/jobs/rails",
    redirect: "/job/rails"
  },
  {
    path: "/jobs/php",
    redirect: "/job/php"
  },
  {
    path: "/jobs/laravel",
    redirect: "/job/laravel"
  },
  {
    path: "/jobs/frontend",
    redirect: "/job/frontend"
  },
  {
    path: "/jobs/wordpress",
    redirect: "/job/wordpress"
  },
  {
    path: "/jobs/iphone",
    redirect: "/job/iphone"
  },
  {
    path: "/jobs/android",
    redirect: "/job/android"
  },
  {
    path: "/jobs/unity",
    redirect: "/job/unity"
  },
  {
    path: "/jobs/java",
    redirect: "/job/java"
  },
  {
    path: "/jobs/blockchain",
    redirect: "/job/blockchain"
  },
  {
    path: "/jobs/ai",
    redirect: "/job/ai"
  },
  {
    path: "/jobs/datascience",
    redirect: "/job/datascience"
  },
  {
    path: "/jobs/webdesign",
    redirect: "/job/webdesign"
  },
  {
    path: "/jobs/appdesign",
    redirect: "/job/appdesign"
  },
  {
    path: "/jobs/webdirection",
    redirect: "/job/webdirection"
  },
  {
    path: "/jobs/webmarketing",
    redirect: "/job/webmarketing"
  },
  {
    path: "/mentor-recruits-lp-rails",
    redirect: "/job/lp/rails"
  },
  {
    path: "/mentor-recruits-lp-appdesign",
    redirect: "/job/lp/appdesign"
  },
  {
    path: "/mentor-recruits-lp-ai",
    redirect: "/job/lp/ai"
  },
  {
    path: "/mentor-recruits-lp-webdesign",
    redirect: "/job/lp/webdesign"
  },
  {
    path: "/mentor-recruits-lp-frontend",
    redirect: "/job/lp/frontend"
  },
  {
    path: "/mentor-recruits-lp-datascience",
    redirect: "/job/lp/datascience"
  },
  {
    path: "/mentor-recruits-lp-webmarketing",
    redirect: "/job/lp/webmarketing"
  },
  {
    path: "/mentor-recruits-lp-unity",
    redirect: "/job/lp/unity"
  },
  {
    path: "/mentor-recruits-lp-laravel",
    redirect: "/job/lp/laravel"
  },
  {
    path: "/mentor-recruits-lp-java-servlet",
    redirect: "/job/lp/java-servlet"
  },
  {
    path: "/mentor-recruits-lp-wordpress",
    redirect: "/job/lp/wordpress"
  },
  {
    path: "/php-bootcamp",
    redirect: "/course/php"
  },
  {
    path: "/java-bootcamp",
    redirect: "/course/java"
  },
  {
    path: "/datascience-bootcamp",
    redirect: "/course/datascience"
  },
  {
    path: "/gas-bootcamp",
    redirect: "/course/gas"
  },
  {
    path: "/appdesign-bootcamp",
    redirect: "/course/appdesign"
  },
  {
    path: "/shopify-bootcamp",
    redirect: "/course/shopify"
  },
  {
    path: "/wordpress-bootcamp",
    redirect: "/course/wordpress"
  },
  {
    path: "/iphone-bootcamp",
    redirect: "/course/iphone"
  },
  {
    path: "/android-bootcamp",
    redirect: "/course/android"
  },
  {
    path: "/unity-bootcamp",
    redirect: "/course/unity"
  },
  {
    path: "/first-programming-bootcamp",
    redirect: "/course/first-programming"
  },
  {
    path: "/video-ad-bootcamp",
    redirect: "/course/video-ad"
  },
  {
    path: "/frontend-bootcamp",
    redirect: "/course/frontend"
  },
  {
    path: "/ai-bootcamp",
    redirect: "/course/ai"
  },
  {
    path: "/oss-bootcamp",
    redirect: "/course/oss"
  },
  {
    path: "/webdesign-freelance",
    redirect: "/course/webdesign-freelance"
  },
  {
    path: "/rails-portfolio-bootcamp",
    redirect: "/course/rails-portfolio"
  },
  {
    path: "/webmarketing-bootcamp",
    redirect: "/course/webmarketing"
  },
  {
    path: "/wix-bootcamp",
    redirect: "/course/wix"
  },
  {
    path: "/videoediting-bootcamp",
    redirect: "/course/videoediting"
  },
  {
    path: "/design-portfolio-bootcamp",
    redirect: "/course/design-portfolio"
  },
  {
    path: "/webdirection-bootcamp",
    redirect: "/course/webdirection"
  },
  {
    path: "/first-dx-bootcamp",
    redirect: "/course/first-dx"
  },
  {
    path: "/course/first-dx",
    redirect: "/"
  },
  {
    path: "/dx-management-bootcamp",
    redirect: "/course/dx-management"
  },
  {
    path: "/frontend-sidejob-bootcamp",
    redirect: "/course/frontend-sidejob"
  },
  {
    path: "/first-prompt-eng-bootcamp",
    redirect: "/course/first-prompt-engineering"
  },
  {
    path: "/trial-mentoring-tutorial",
    redirect: "/tutorial/trial-mentoring"
  },
  {
    path: "/counselling-tutorial",
    redirect: "/tutorial/counseling"
  },
  {
    path: "/htmlcss-training",
    redirect: "/training/htmlcss"
  },
  {
    path: "/bootstrap-training",
    redirect: "/training/bootstrap"
  },
  {
    path: "/github-training",
    redirect: "/training/github"
  },
  {
    path: "/photoshop-training",
    redirect: "/training/photoshop"
  },
  {
    path: "/pro/lp/jobchange_trial",
    redirect: "/pro/lp-s/jobchange-trial"
  },
  {
    path: "/webapp-frontend-set",
    redirect: "/set/rails-frontend"
  },
  {
    path: "/php-frontend-set",
    redirect: "/set/php-frontend"
  },
  {
    path: "/webdesign-wordpress-set",
    redirect: "/set/webdesign-wordpress"
  },
  {
    path: "/webdesign-webmarketing-set",
    redirect: "/set/webdesign-webmarketing"
  },
  {
    path: "/wordpress-webmarketing-set",
    redirect: "/set/wordpress-webmarketing"
  },
  {
    path: "/python-ai-set",
    redirect: "/set/python-ai"
  },
  {
    path: "/python-datascience-set",
    redirect: "/set/python-datascience"
  },
  {
    path: "/iphone-android-set",
    redirect: "/set/iphone-android"
  },
  {
    path: "/unity-iphone-set",
    redirect: "/set/unity-iphone"
  },
  {
    path: "/unity-android-set",
    redirect: "/set/unity-android"
  },
  {
    path: "/appdesign-iphone-set",
    redirect: "/set/appdesign-iphone"
  },
  {
    path: "/webdirection-webdesign-set",
    redirect: "/set/webdirection-webdesign"
  },
  {
    path: "/webdirection-webmarketing-set",
    redirect: "/set/webdirection-webmarketing"
  },
  {
    path: "/webapp-frontend-webdesign-set",
    redirect: "/set/rails-frontend-webdesign"
  },
  {
    path: "/php-frontend-webdesign-set",
    redirect: "/set/php-frontend-webdesign"
  },
  {
    path: "/iphone-android-appdesign-set",
    redirect: "/set/iphone-android-appdesign"
  },
  {
    path: "/python-ai-datascience-set",
    redirect: "/set/python-ai-datascience"
  },
  {
    path: "/webdesign-freelance-set",
    redirect: "/set/webdesign-freelance"
  },
  {
    path: "/frontend-sidejob-set",
    redirect: "/set/frontend-sidejob"
  },
  {
    path: "/python-prompt-set",
    redirect: "/set/python-first-prompt-engineering"
  },
  {
    path: "/lp-webdesign-freelance-s",
    redirect: "/set/webdesign-freelance-high-class-mentor"
  },
  {
    path: "/course/videoediting",
    redirect: "/moocres"
  },
  {
    path: "/briefing/videoediting",
    redirect: "/moocres"
  },
  {
    path: "/briefing/lp-s/videoediting",
    redirect: "/moocres"
  },
  {
    path: "/course/video-ad",
    redirect: "/moocres"
  },
  {
    path: "/briefing/video-ad",
    redirect: "/moocres"
  },
  {
    path: "/briefing/lp-s/video-ad",
    redirect: "/moocres"
  },
  {
    path: "/course/dx-management",
    redirect: "/course"
  },
  {
    path: "/course/webdirection",
    redirect: "/course"
  },
  {
    path: "/briefing/webdirection",
    redirect: "/course"
  },
  {
    path: "/briefing/lp-s/webdirection",
    redirect: "/course"
  },
  {
    path: "/set/webdirection-webdesign",
    redirect: "/course"
  },
  {
    path: "/set/webdirection-webmarketing",
    redirect: "/course"
  },
  {
    path: "/course/gas",
    redirect: "/course"
  },
  {
    path: "/briefing/gas",
    redirect: "/course"
  },
  {
    path: "/briefing/lp-s/gas",
    redirect: "/course"
  },
  {
    path: "/course/rails-portfolio",
    redirect: "/course"
  },
  {
    path: "/briefing/rails-portfolio",
    redirect: "/course"
  },
  {
    path: "/course/oss",
    redirect: "/course"
  },
  {
    path: "/job/webdirection",
    redirect: "/job"
  },
  {
    path: "/briefing/first-prompt-eng",
    redirect: "/briefing/first-prompt-engineering"
  },
  {
    path: "/web-production-mom-bootcamp",
    redirect: "/course/first-sidejob-mom"
  },
  {
    path: "/moocres-bootcamp",
    redirect: "/moocres"
  },
  {
    path: "/counseling/htmlcss-schedule-3",
    redirect: "/counseling/htmlcss-schedule"
  },
  {
    path: "/ly/counseling/lp/htmlcss-schedule",
    redirect: "/ly/counseling"
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-course-first-prompt-engineering",
    path: "/biz/training/course/first-prompt-engineering",
    component: () => import("/vercel/path0/pages/biz/training/course/first-prompt-engineering/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-dx",
    path: "/biz/training/dx",
    component: () => import("/vercel/path0/pages/biz/training/dx/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training",
    path: "/biz/training",
    meta: indexVf2tJc0DDdMeta || {},
    component: () => import("/vercel/path0/pages/biz/training/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-it-completed",
    path: "/biz/training/it/completed",
    component: () => import("/vercel/path0/pages/biz/training/it/completed.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-it-contact-completed",
    path: "/biz/training/it/contact/completed",
    component: () => import("/vercel/path0/pages/biz/training/it/contact/completed.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-it-contact",
    path: "/biz/training/it/contact",
    component: () => import("/vercel/path0/pages/biz/training/it/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-it",
    path: "/biz/training/it",
    component: () => import("/vercel/path0/pages/biz/training/it/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-lp-team-team",
    path: "/biz/training/lp/team/:team()",
    component: () => import("/vercel/path0/pages/biz/training/lp/team/[team]/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-special-generative-ai-series",
    path: "/biz/training/special/generative-ai-series",
    component: () => import("/vercel/path0/pages/biz/training/special/generative-ai-series/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-training-trial-htmlcss-trial",
    path: "/biz/training/trial/htmlcss-trial",
    component: () => import("/vercel/path0/pages/biz/training/trial/htmlcss-trial.vue").then(m => m.default || m)
  },
  {
    name: "biz-works",
    path: "/biz/works",
    component: () => import("/vercel/path0/pages/biz/works.vue").then(m => m.default || m)
  },
  {
    name: "briefing-ai",
    path: "/briefing/ai",
    meta: aiFT7foetBqbMeta || {},
    component: () => import("/vercel/path0/pages/briefing/ai.vue").then(m => m.default || m)
  },
  {
    name: "briefing-all",
    path: "/briefing/all",
    component: () => import("/vercel/path0/pages/briefing/all.vue").then(m => m.default || m)
  },
  {
    name: "briefing-android",
    path: "/briefing/android",
    component: () => import("/vercel/path0/pages/briefing/android.vue").then(m => m.default || m)
  },
  {
    name: "briefing-datascience",
    path: "/briefing/datascience",
    meta: datascienceD8sSIKy6CsMeta || {},
    component: () => import("/vercel/path0/pages/briefing/datascience.vue").then(m => m.default || m)
  },
  {
    name: "briefing-first-programming",
    path: "/briefing/first-programming",
    component: () => import("/vercel/path0/pages/briefing/first-programming.vue").then(m => m.default || m)
  },
  {
    name: "briefing-first-prompt-engineering",
    path: "/briefing/first-prompt-engineering",
    component: () => import("/vercel/path0/pages/briefing/first-prompt-engineering.vue").then(m => m.default || m)
  },
  {
    name: "briefing-first-sidejob",
    path: "/briefing/first-sidejob",
    meta: first_45sidejobGLcWrLlh5eMeta || {},
    component: () => import("/vercel/path0/pages/briefing/first-sidejob.vue").then(m => m.default || m)
  },
  {
    name: "briefing-frontend",
    path: "/briefing/frontend",
    meta: frontendAoJ0WCA9F6Meta || {},
    component: () => import("/vercel/path0/pages/briefing/frontend.vue").then(m => m.default || m)
  },
  {
    name: "briefing",
    path: "/briefing",
    component: () => import("/vercel/path0/pages/briefing/index.vue").then(m => m.default || m)
  },
  {
    name: "briefing-iphone",
    path: "/briefing/iphone",
    component: () => import("/vercel/path0/pages/briefing/iphone.vue").then(m => m.default || m)
  },
  {
    name: "briefing-java",
    path: "/briefing/java",
    component: () => import("/vercel/path0/pages/briefing/java.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-ai",
    path: "/briefing/lp-s/ai",
    component: () => import("/vercel/path0/pages/briefing/lp-s/ai.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-all",
    path: "/briefing/lp-s/all",
    component: () => import("/vercel/path0/pages/briefing/lp-s/all.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-android",
    path: "/briefing/lp-s/android",
    component: () => import("/vercel/path0/pages/briefing/lp-s/android.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-datascience",
    path: "/briefing/lp-s/datascience",
    component: () => import("/vercel/path0/pages/briefing/lp-s/datascience.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-first-programming",
    path: "/briefing/lp-s/first-programming",
    component: () => import("/vercel/path0/pages/briefing/lp-s/first-programming.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-first-prompt-engineering",
    path: "/briefing/lp-s/first-prompt-engineering",
    component: () => import("/vercel/path0/pages/briefing/lp-s/first-prompt-engineering.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-first-sidejob",
    path: "/briefing/lp-s/first-sidejob",
    meta: first_45sidejobKDX5KmD914Meta || {},
    component: () => import("/vercel/path0/pages/briefing/lp-s/first-sidejob.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-frontend",
    path: "/briefing/lp-s/frontend",
    component: () => import("/vercel/path0/pages/briefing/lp-s/frontend.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-iphone",
    path: "/briefing/lp-s/iphone",
    component: () => import("/vercel/path0/pages/briefing/lp-s/iphone.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-java",
    path: "/briefing/lp-s/java",
    component: () => import("/vercel/path0/pages/briefing/lp-s/java.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-programming",
    path: "/briefing/lp-s/programming",
    component: () => import("/vercel/path0/pages/briefing/lp-s/programming.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-python",
    path: "/briefing/lp-s/python",
    component: () => import("/vercel/path0/pages/briefing/lp-s/python.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-unity",
    path: "/briefing/lp-s/unity",
    component: () => import("/vercel/path0/pages/briefing/lp-s/unity.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-webdesign",
    path: "/briefing/lp-s/webdesign",
    component: () => import("/vercel/path0/pages/briefing/lp-s/webdesign.vue").then(m => m.default || m)
  },
  {
    name: "briefing-lp-s-webmarketing",
    path: "/briefing/lp-s/webmarketing",
    component: () => import("/vercel/path0/pages/briefing/lp-s/webmarketing.vue").then(m => m.default || m)
  },
  {
    name: "briefing-moocres",
    path: "/briefing/moocres",
    component: () => import("/vercel/path0/pages/briefing/moocres.vue").then(m => m.default || m)
  },
  {
    name: "briefing-python",
    path: "/briefing/python",
    meta: pythontpvJAy4SEiMeta || {},
    component: () => import("/vercel/path0/pages/briefing/python.vue").then(m => m.default || m)
  },
  {
    name: "briefing-unity",
    path: "/briefing/unity",
    component: () => import("/vercel/path0/pages/briefing/unity.vue").then(m => m.default || m)
  },
  {
    name: "briefing-webdesign",
    path: "/briefing/webdesign",
    component: () => import("/vercel/path0/pages/briefing/webdesign.vue").then(m => m.default || m)
  },
  {
    name: "briefing-webmarketing",
    path: "/briefing/webmarketing",
    component: () => import("/vercel/path0/pages/briefing/webmarketing.vue").then(m => m.default || m)
  },
  {
    name: "career-about",
    path: "/career-about",
    component: () => import("/vercel/path0/pages/career-about/index.vue").then(m => m.default || m)
  },
  {
    name: "company",
    path: "/company",
    meta: indexTHqTRxlhV2Meta || {},
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "cookiepolicy",
    path: "/cookiepolicy",
    meta: indexuIEwXf9C56Meta || {},
    component: () => import("/vercel/path0/pages/cookiepolicy/index.vue").then(m => m.default || m)
  },
  {
    name: "counseling-htmlcss-schedule-trial1",
    path: "/counseling/htmlcss-schedule-trial1",
    component: () => import("/vercel/path0/pages/counseling/htmlcss-schedule-trial1.vue").then(m => m.default || m)
  },
  {
    name: "counseling-htmlcss-schedule-trial2",
    path: "/counseling/htmlcss-schedule-trial2",
    component: () => import("/vercel/path0/pages/counseling/htmlcss-schedule-trial2.vue").then(m => m.default || m)
  },
  {
    name: "counseling-htmlcss-schedule",
    path: "/counseling/htmlcss-schedule",
    component: () => import("/vercel/path0/pages/counseling/htmlcss-schedule.vue").then(m => m.default || m)
  },
  {
    name: "counseling-lp-htmlcss-schedule-form",
    path: "/counseling/lp/htmlcss-schedule-form",
    meta: htmlcss_45schedule_45formafT3JCCY7NMeta || {},
    component: () => import("/vercel/path0/pages/counseling/lp/htmlcss-schedule-form.vue").then(m => m.default || m)
  },
  {
    name: "counseling-lp-htmlcss-schedule",
    path: "/counseling/lp/htmlcss-schedule",
    meta: htmlcss_45schedule491xgB0GzFMeta || {},
    component: () => import("/vercel/path0/pages/counseling/lp/htmlcss-schedule.vue").then(m => m.default || m)
  },
  {
    name: "course-ai",
    path: "/course/ai",
    component: () => import("/vercel/path0/pages/course/ai.vue").then(m => m.default || m)
  },
  {
    name: "course-android",
    path: "/course/android",
    component: () => import("/vercel/path0/pages/course/android.vue").then(m => m.default || m)
  },
  {
    name: "course-datascience",
    path: "/course/datascience",
    component: () => import("/vercel/path0/pages/course/datascience.vue").then(m => m.default || m)
  },
  {
    name: "course-design-portfolio",
    path: "/course/design-portfolio",
    component: () => import("/vercel/path0/pages/course/design-portfolio.vue").then(m => m.default || m)
  },
  {
    name: "course-first-programming",
    path: "/course/first-programming",
    component: () => import("/vercel/path0/pages/course/first-programming.vue").then(m => m.default || m)
  },
  {
    name: "course-first-prompt-engineering",
    path: "/course/first-prompt-engineering",
    component: () => import("/vercel/path0/pages/course/first-prompt-engineering.vue").then(m => m.default || m)
  },
  {
    name: "course-first-sidejob",
    path: "/course/first-sidejob",
    component: () => import("/vercel/path0/pages/course/first-sidejob.vue").then(m => m.default || m)
  },
  {
    name: "course-frontend",
    path: "/course/frontend",
    component: () => import("/vercel/path0/pages/course/frontend.vue").then(m => m.default || m)
  },
  {
    name: "course",
    path: "/course",
    component: () => import("/vercel/path0/pages/course/index.vue").then(m => m.default || m)
  },
  {
    name: "course-iphone",
    path: "/course/iphone",
    component: () => import("/vercel/path0/pages/course/iphone.vue").then(m => m.default || m)
  },
  {
    name: "course-java",
    path: "/course/java",
    component: () => import("/vercel/path0/pages/course/java.vue").then(m => m.default || m)
  },
  {
    name: "course-llm-development",
    path: "/course/llm-development",
    component: () => import("/vercel/path0/pages/course/llm-development.vue").then(m => m.default || m)
  },
  {
    name: "course-occupation",
    path: "/course/occupation",
    component: () => import("/vercel/path0/pages/course/occupation.vue").then(m => m.default || m)
  },
  {
    name: "course-python",
    path: "/course/python",
    component: () => import("/vercel/path0/pages/course/python.vue").then(m => m.default || m)
  },
  {
    name: "course-unity",
    path: "/course/unity",
    component: () => import("/vercel/path0/pages/course/unity.vue").then(m => m.default || m)
  },
  {
    name: "course-webdesign",
    path: "/course/webdesign",
    component: () => import("/vercel/path0/pages/course/webdesign.vue").then(m => m.default || m)
  },
  {
    name: "course-webdevelopment-advanced",
    path: "/course/webdevelopment-advanced",
    component: () => import("/vercel/path0/pages/course/webdevelopment-advanced.vue").then(m => m.default || m)
  },
  {
    name: "course-webmarketing",
    path: "/course/webmarketing",
    component: () => import("/vercel/path0/pages/course/webmarketing.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_a",
    path: "/diagnoses/completed_a",
    component: () => import("/vercel/path0/pages/diagnoses/completed_a.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_b",
    path: "/diagnoses/completed_b",
    component: () => import("/vercel/path0/pages/diagnoses/completed_b.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_c",
    path: "/diagnoses/completed_c",
    component: () => import("/vercel/path0/pages/diagnoses/completed_c.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_d",
    path: "/diagnoses/completed_d",
    component: () => import("/vercel/path0/pages/diagnoses/completed_d.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_e",
    path: "/diagnoses/completed_e",
    component: () => import("/vercel/path0/pages/diagnoses/completed_e.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_f",
    path: "/diagnoses/completed_f",
    component: () => import("/vercel/path0/pages/diagnoses/completed_f.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_g",
    path: "/diagnoses/completed_g",
    component: () => import("/vercel/path0/pages/diagnoses/completed_g.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_i",
    path: "/diagnoses/completed_i",
    component: () => import("/vercel/path0/pages/diagnoses/completed_i.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_j",
    path: "/diagnoses/completed_j",
    component: () => import("/vercel/path0/pages/diagnoses/completed_j.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_k",
    path: "/diagnoses/completed_k",
    component: () => import("/vercel/path0/pages/diagnoses/completed_k.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_l",
    path: "/diagnoses/completed_l",
    component: () => import("/vercel/path0/pages/diagnoses/completed_l.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_n",
    path: "/diagnoses/completed_n",
    component: () => import("/vercel/path0/pages/diagnoses/completed_n.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_o",
    path: "/diagnoses/completed_o",
    component: () => import("/vercel/path0/pages/diagnoses/completed_o.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_p",
    path: "/diagnoses/completed_p",
    component: () => import("/vercel/path0/pages/diagnoses/completed_p.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_q",
    path: "/diagnoses/completed_q",
    component: () => import("/vercel/path0/pages/diagnoses/completed_q.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_r",
    path: "/diagnoses/completed_r",
    component: () => import("/vercel/path0/pages/diagnoses/completed_r.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_s",
    path: "/diagnoses/completed_s",
    component: () => import("/vercel/path0/pages/diagnoses/completed_s.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-completed_t",
    path: "/diagnoses/completed_t",
    component: () => import("/vercel/path0/pages/diagnoses/completed_t.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q1",
    path: "/diagnoses/q1",
    component: () => import("/vercel/path0/pages/diagnoses/q1.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q2_a",
    path: "/diagnoses/q2_a",
    component: () => import("/vercel/path0/pages/diagnoses/q2_a.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q2_b",
    path: "/diagnoses/q2_b",
    component: () => import("/vercel/path0/pages/diagnoses/q2_b.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q2_c",
    path: "/diagnoses/q2_c",
    component: () => import("/vercel/path0/pages/diagnoses/q2_c.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q2_d",
    path: "/diagnoses/q2_d",
    component: () => import("/vercel/path0/pages/diagnoses/q2_d.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q2_e",
    path: "/diagnoses/q2_e",
    component: () => import("/vercel/path0/pages/diagnoses/q2_e.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q3_a",
    path: "/diagnoses/q3_a",
    component: () => import("/vercel/path0/pages/diagnoses/q3_a.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q3_b",
    path: "/diagnoses/q3_b",
    component: () => import("/vercel/path0/pages/diagnoses/q3_b.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q3_c",
    path: "/diagnoses/q3_c",
    component: () => import("/vercel/path0/pages/diagnoses/q3_c.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q3_f",
    path: "/diagnoses/q3_f",
    component: () => import("/vercel/path0/pages/diagnoses/q3_f.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-q4_a",
    path: "/diagnoses/q4_a",
    component: () => import("/vercel/path0/pages/diagnoses/q4_a.vue").then(m => m.default || m)
  },
  {
    name: "diagnoses-top",
    path: "/diagnoses/top",
    component: () => import("/vercel/path0/pages/diagnoses/top.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "job-ai",
    path: "/job/ai",
    component: () => import("/vercel/path0/pages/job/ai.vue").then(m => m.default || m)
  },
  {
    name: "job-android",
    path: "/job/android",
    component: () => import("/vercel/path0/pages/job/android.vue").then(m => m.default || m)
  },
  {
    name: "job-candidate-token-profile",
    path: "/job/candidate/:token()/profile",
    meta: profile0o0bBX4Lk3Meta || {},
    component: () => import("/vercel/path0/pages/job/candidate/[token]/profile.vue").then(m => m.default || m)
  },
  {
    name: "job-datascience",
    path: "/job/datascience",
    component: () => import("/vercel/path0/pages/job/datascience.vue").then(m => m.default || m)
  },
  {
    name: "job-frontend",
    path: "/job/frontend",
    component: () => import("/vercel/path0/pages/job/frontend.vue").then(m => m.default || m)
  },
  {
    name: "job",
    path: "/job",
    component: () => import("/vercel/path0/pages/job/index.vue").then(m => m.default || m)
  },
  {
    name: "job-iphone",
    path: "/job/iphone",
    component: () => import("/vercel/path0/pages/job/iphone.vue").then(m => m.default || m)
  },
  {
    name: "job-java",
    path: "/job/java",
    component: () => import("/vercel/path0/pages/job/java.vue").then(m => m.default || m)
  },
  {
    name: "job-laravel",
    path: "/job/laravel",
    component: () => import("/vercel/path0/pages/job/laravel.vue").then(m => m.default || m)
  },
  {
    name: "job-php",
    path: "/job/php",
    component: () => import("/vercel/path0/pages/job/php.vue").then(m => m.default || m)
  },
  {
    name: "job-unity",
    path: "/job/unity",
    component: () => import("/vercel/path0/pages/job/unity.vue").then(m => m.default || m)
  },
  {
    name: "job-webdesign",
    path: "/job/webdesign",
    component: () => import("/vercel/path0/pages/job/webdesign.vue").then(m => m.default || m)
  },
  {
    name: "job-webmarketing",
    path: "/job/webmarketing",
    component: () => import("/vercel/path0/pages/job/webmarketing.vue").then(m => m.default || m)
  },
  {
    name: "jobchange",
    path: "/jobchange",
    component: () => import("/vercel/path0/pages/jobchange/index.vue").then(m => m.default || m)
  },
  {
    name: "law",
    path: "/law",
    meta: indexwBWcviDWKwMeta || {},
    component: () => import("/vercel/path0/pages/law/index.vue").then(m => m.default || m)
  },
  {
    name: "lp-course-first-sidejob-immersive",
    path: "/lp/course/first-sidejob-immersive",
    component: () => import("/vercel/path0/pages/lp/course/first-sidejob-immersive.vue").then(m => m.default || m)
  },
  {
    name: "lp-course-first-sidejob",
    path: "/lp/course/first-sidejob",
    component: () => import("/vercel/path0/pages/lp/course/first-sidejob.vue").then(m => m.default || m)
  },
  {
    name: "lp-moocres-schedule-form",
    path: "/lp/moocres/schedule-form",
    meta: schedule_45formUCvohPgBYMMeta || {},
    component: () => import("/vercel/path0/pages/lp/moocres/schedule-form.vue").then(m => m.default || m)
  },
  {
    name: "ly-contact-biz",
    path: "/ly/contact-biz",
    component: () => import("/vercel/path0/pages/ly/contact-biz.vue").then(m => m.default || m)
  },
  {
    name: "ly-contact",
    path: "/ly/contact",
    component: () => import("/vercel/path0/pages/ly/contact.vue").then(m => m.default || m)
  },
  {
    name: "ly-counseling-2",
    path: "/ly/counseling-2",
    component: () => import("/vercel/path0/pages/ly/counseling-2.vue").then(m => m.default || m)
  },
  {
    name: "ly-counseling-line",
    path: "/ly/counseling-line",
    component: () => import("/vercel/path0/pages/ly/counseling-line.vue").then(m => m.default || m)
  },
  {
    name: "ly-counseling",
    path: "/ly/counseling",
    component: () => import("/vercel/path0/pages/ly/counseling.vue").then(m => m.default || m)
  },
  {
    name: "ly-course-data-basic",
    path: "/ly/course/data-basic",
    component: () => import("/vercel/path0/pages/ly/course/data-basic.vue").then(m => m.default || m)
  },
  {
    name: "ly-course-jobchange",
    path: "/ly/course/jobchange",
    component: () => import("/vercel/path0/pages/ly/course/jobchange.vue").then(m => m.default || m)
  },
  {
    name: "ly-course-webapp-basic",
    path: "/ly/course/webapp-basic",
    component: () => import("/vercel/path0/pages/ly/course/webapp-basic.vue").then(m => m.default || m)
  },
  {
    name: "ly-course-webapp",
    path: "/ly/course/webapp",
    component: () => import("/vercel/path0/pages/ly/course/webapp.vue").then(m => m.default || m)
  },
  {
    name: "ly-law",
    path: "/ly/law",
    component: () => import("/vercel/path0/pages/ly/law.vue").then(m => m.default || m)
  },
  {
    name: "ly-login",
    path: "/ly/login",
    component: () => import("/vercel/path0/pages/ly/login.vue").then(m => m.default || m)
  },
  {
    name: "ly-lp-counseling-2",
    path: "/ly/lp/counseling-2",
    component: () => import("/vercel/path0/pages/ly/lp/counseling-2.vue").then(m => m.default || m)
  },
  {
    name: "ly-lp-counseling-3",
    path: "/ly/lp/counseling-3",
    component: () => import("/vercel/path0/pages/ly/lp/counseling-3.vue").then(m => m.default || m)
  },
  {
    name: "ly-lp-counseling-4",
    path: "/ly/lp/counseling-4",
    component: () => import("/vercel/path0/pages/ly/lp/counseling-4.vue").then(m => m.default || m)
  },
  {
    name: "ly-lp-counseling-kakaku",
    path: "/ly/lp/counseling-kakaku",
    component: () => import("/vercel/path0/pages/ly/lp/counseling-kakaku.vue").then(m => m.default || m)
  },
  {
    name: "ly-lp-counseling",
    path: "/ly/lp/counseling",
    component: () => import("/vercel/path0/pages/ly/lp/counseling.vue").then(m => m.default || m)
  },
  {
    name: "ly-privacypolicy",
    path: "/ly/privacypolicy",
    component: () => import("/vercel/path0/pages/ly/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: "ly-terms",
    path: "/ly/terms",
    component: () => import("/vercel/path0/pages/ly/terms.vue").then(m => m.default || m)
  },
  {
    name: "ly-tutorial-trial-mentoring",
    path: "/ly/tutorial/trial-mentoring",
    meta: trial_45mentoringoHiCcFNejhMeta || {},
    component: () => import("/vercel/path0/pages/ly/tutorial/trial-mentoring.vue").then(m => m.default || m)
  },
  {
    name: "ly-users-passwords",
    path: "/ly/users/passwords",
    component: () => import("/vercel/path0/pages/ly/users/passwords.vue").then(m => m.default || m)
  },
  {
    name: "price",
    path: "/price",
    component: () => import("/vercel/path0/pages/price/index.vue").then(m => m.default || m)
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    meta: indexbg3ekTJ1BXMeta || {},
    component: () => import("/vercel/path0/pages/privacypolicy/index.vue").then(m => m.default || m)
  },
  {
    name: "set-first-sidejob-webdesign",
    path: "/set/first-sidejob-webdesign",
    component: () => import("/vercel/path0/pages/set/first-sidejob-webdesign.vue").then(m => m.default || m)
  },
  {
    name: "set-iphone-android",
    path: "/set/iphone-android",
    component: () => import("/vercel/path0/pages/set/iphone-android.vue").then(m => m.default || m)
  },
  {
    name: "set-python-ai-datascience",
    path: "/set/python-ai-datascience",
    component: () => import("/vercel/path0/pages/set/python-ai-datascience.vue").then(m => m.default || m)
  },
  {
    name: "set-python-ai",
    path: "/set/python-ai",
    component: () => import("/vercel/path0/pages/set/python-ai.vue").then(m => m.default || m)
  },
  {
    name: "set-python-datascience",
    path: "/set/python-datascience",
    component: () => import("/vercel/path0/pages/set/python-datascience.vue").then(m => m.default || m)
  },
  {
    name: "set-python-first-prompt-engineering",
    path: "/set/python-first-prompt-engineering",
    component: () => import("/vercel/path0/pages/set/python-first-prompt-engineering.vue").then(m => m.default || m)
  },
  {
    name: "set-unity-android",
    path: "/set/unity-android",
    component: () => import("/vercel/path0/pages/set/unity-android.vue").then(m => m.default || m)
  },
  {
    name: "set-unity-iphone",
    path: "/set/unity-iphone",
    component: () => import("/vercel/path0/pages/set/unity-iphone.vue").then(m => m.default || m)
  },
  {
    name: "set-webdesign-webmarketing",
    path: "/set/webdesign-webmarketing",
    component: () => import("/vercel/path0/pages/set/webdesign-webmarketing.vue").then(m => m.default || m)
  },
  {
    name: "set-webdevelopment-advanced",
    path: "/set/webdevelopment-advanced",
    component: () => import("/vercel/path0/pages/set/webdevelopment-advanced.vue").then(m => m.default || m)
  },
  {
    name: "sidejob",
    path: "/sidejob",
    component: () => import("/vercel/path0/pages/sidejob/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: index9OiDgfx7OcMeta || {},
    component: () => import("/vercel/path0/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "training-photoshop",
    path: "/training/photoshop",
    component: () => import("/vercel/path0/pages/training/photoshop.vue").then(m => m.default || m)
  },
  {
    name: "trial-htmlcss-trial",
    path: "/trial/htmlcss-trial",
    component: () => import("/vercel/path0/pages/trial/htmlcss-trial.vue").then(m => m.default || m)
  },
  {
    name: "trial-lp-htmlcss-trial-schedule",
    path: "/trial/lp/htmlcss-trial-schedule",
    component: () => import("/vercel/path0/pages/trial/lp/htmlcss-trial-schedule.vue").then(m => m.default || m)
  },
  {
    name: "trial-lp-htmlcss-trial-sidejob",
    path: "/trial/lp/htmlcss-trial-sidejob",
    component: () => import("/vercel/path0/pages/trial/lp/htmlcss-trial-sidejob.vue").then(m => m.default || m)
  },
  {
    name: "trial-lp-htmlcss-trial",
    path: "/trial/lp/htmlcss-trial",
    component: () => import("/vercel/path0/pages/trial/lp/htmlcss-trial.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-trial-mentoring",
    path: "/tutorial/trial-mentoring",
    component: () => import("/vercel/path0/pages/tutorial/trial-mentoring.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-axios",
    path: "/upgrade/axios",
    component: () => import("/vercel/path0/pages/upgrade/axios.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-components",
    path: "/upgrade/components",
    component: () => import("/vercel/path0/pages/upgrade/components.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-css-heading",
    path: "/upgrade/css/heading",
    component: () => import("/vercel/path0/pages/upgrade/css/heading.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-accordion",
    path: "/upgrade/daisyui/accordion",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/accordion.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-collapse",
    path: "/upgrade/daisyui/collapse",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/collapse.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-dropdown",
    path: "/upgrade/daisyui/dropdown",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/dropdown.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-form-design",
    path: "/upgrade/daisyui/form-design",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/form-design.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-form",
    path: "/upgrade/daisyui/form",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/form.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-modal-appyform",
    path: "/upgrade/daisyui/modal-appyform",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/modal-appyform.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-modal-footer-button",
    path: "/upgrade/daisyui/modal-footer-button",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/modal-footer-button.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-modal",
    path: "/upgrade/daisyui/modal",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/modal.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-daisyui-tooltip",
    path: "/upgrade/daisyui/tooltip",
    component: () => import("/vercel/path0/pages/upgrade/daisyui/tooltip.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-docs-how_to-02_tailwind",
    path: "/upgrade/docs/how_to/02_tailwind",
    component: () => import("/vercel/path0/pages/upgrade/docs/how_to/02_tailwind.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-fontawesome-course-icon",
    path: "/upgrade/fontawesome/course-icon",
    component: () => import("/vercel/path0/pages/upgrade/fontawesome/course-icon.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-fontawesome",
    path: "/upgrade/fontawesome",
    component: () => import("/vercel/path0/pages/upgrade/fontawesome/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-head",
    path: "/upgrade/head",
    component: () => import("/vercel/path0/pages/upgrade/head.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-inview-intersection-observer",
    path: "/upgrade/inview/intersection-observer",
    component: () => import("/vercel/path0/pages/upgrade/inview/intersection-observer.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-inview-vueuse-element-visibility",
    path: "/upgrade/inview/vueuse-element-visibility",
    component: () => import("/vercel/path0/pages/upgrade/inview/vueuse-element-visibility.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-inview-vueuse-intersection-observer",
    path: "/upgrade/inview/vueuse-intersection-observer",
    component: () => import("/vercel/path0/pages/upgrade/inview/vueuse-intersection-observer.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-jquery-collapse-panel",
    path: "/upgrade/jquery/collapse-panel",
    component: () => import("/vercel/path0/pages/upgrade/jquery/collapse-panel.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-jquery-icon",
    path: "/upgrade/jquery/icon",
    component: () => import("/vercel/path0/pages/upgrade/jquery/icon.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-layout-layout-briefing",
    path: "/upgrade/layout/layout-briefing",
    meta: layout_45briefingLJkaj364qmMeta || {},
    component: () => import("/vercel/path0/pages/upgrade/layout/layout-briefing.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-layout-layout-default",
    path: "/upgrade/layout/layout-default",
    component: () => import("/vercel/path0/pages/upgrade/layout/layout-default.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-layout-layout-simple-catchcopy",
    path: "/upgrade/layout/layout-simple-catchcopy",
    meta: layout_45simple_45catchcopylp82sfhp6iMeta || {},
    component: () => import("/vercel/path0/pages/upgrade/layout/layout-simple-catchcopy.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-layout-layout-simple",
    path: "/upgrade/layout/layout-simple",
    meta: layout_45simpleywx3vsCuo2Meta || {},
    component: () => import("/vercel/path0/pages/upgrade/layout/layout-simple.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-nuxt-swiper-responsive-reviews",
    path: "/upgrade/nuxt-swiper/responsive-reviews",
    component: () => import("/vercel/path0/pages/upgrade/nuxt-swiper/responsive-reviews.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-nuxt-swiper-responsive-slide-three",
    path: "/upgrade/nuxt-swiper/responsive-slide-three",
    component: () => import("/vercel/path0/pages/upgrade/nuxt-swiper/responsive-slide-three.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-nuxt-swiper-responsive-slide-two",
    path: "/upgrade/nuxt-swiper/responsive-slide-two",
    component: () => import("/vercel/path0/pages/upgrade/nuxt-swiper/responsive-slide-two.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-scroll-spy",
    path: "/upgrade/scroll-spy",
    component: () => import("/vercel/path0/pages/upgrade/scroll-spy/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-scroll-trigger",
    path: "/upgrade/scroll-trigger",
    component: () => import("/vercel/path0/pages/upgrade/scroll-trigger/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-smooth-scroll",
    path: "/upgrade/smooth-scroll",
    component: () => import("/vercel/path0/pages/upgrade/smooth-scroll/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-vue3-lottie",
    path: "/upgrade/vue3-lottie",
    component: () => import("/vercel/path0/pages/upgrade/vue3-lottie/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-youtube",
    path: "/upgrade/youtube",
    component: () => import("/vercel/path0/pages/upgrade/youtube/index.vue").then(m => m.default || m)
  },
  {
    name: "works",
    path: "/works",
    component: () => import("/vercel/path0/pages/works/index.vue").then(m => m.default || m)
  }
]